<template>
  <div>
    <div v-if="this.$route.name === 'Settings'">
      <Settings />
    </div>
    <router-view />
  </div>
</template>
<script>
import Settings from "@/views/adminApp/settings/Settings.vue";

export default {
  name: 'SettingsParent',
  components: {
    Settings
  }
}
</script>
