<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="mb-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{$t('toolbar.settings')}}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card outlined>
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.icon"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ $t(tab.title) }}</span>
            </v-tab>
          </v-tabs>
          <v-divider />
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <account-settings-security></account-settings-security>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AccountSettingsSecurity from './SettingsTab/AccountSettingsSecurity.vue'

export default {
  name: 'Settings',
  components: {
    AccountSettingsSecurity,
  },
  data() {
    return {
      tab: null,
      tabs: [
        { title: 'settings.security', icon: "mdi-lock-open-outline" },
      ]
    }
  },
}
</script>
